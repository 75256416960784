import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid, TextField,
} from 'react-admin'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Company'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>

        </Filter>
    )
}

const DashboardCompany: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Companies"

            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'No Data'} hasCreate={false}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source={'email'} label={'Email'}/>
                <TextField source={'company'} label={'Company'}/>
                <TextField source={'company_cvs'} label={'Email'}/>
                <TextField source={'total_vacancies'} label={'Vacancies'}/>
                <TextField source={'draft_vacancies'} label={'Draft Vacancies'}/>
                <TextField source={'published_vacancies'} label={'Published vacancies'}/>

                <TextField source={'paused_vacancies'} label={'Paused vacancies'}/>
                <TextField source={'closed_vacancies'} label={'Closed vacancies'}/>
                <TextField source={'candidates_count'} label={'Candidates'}/>
                <TextField source={'candidates_applied_count'} label={'Candidates applied'}/>
                <TextField source={'candidates_in_review_count'} label={'Candidates In Review'}/>
                <TextField source={'candidates_offer_count'} label={'Candidates Offer'}/>
                <TextField source={'total_cv_evaluation'} label={'CV scored'}/>
            </Datagrid>
        </List>
    )
}

export default DashboardCompany
