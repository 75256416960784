
export enum SubscriptionPeriod {
  OneMonth = 1,
  ThreeMonths = 3,
  Year = 12,
}
export const SubscriptionPeriodList = [
  {id: SubscriptionPeriod.OneMonth, name: '1 month'},
  {id: SubscriptionPeriod.ThreeMonths, name: '3 months'},
  {id: SubscriptionPeriod.Year, name: '1 year'},
]
